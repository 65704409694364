import { NO_EPG_ID, epgProgramsModel, inRange } from '$/epg/programs'
import { combine, type Store } from 'effector'
import { previous } from 'patronum'
import { channelsEntity } from '~/entities/channels'
import { model as clock } from '~/shared/clock'
import { ONE_SECOND_MS } from '~/shared/constants'

const NO_EDG_DATA = 'No EPG data'

// currently playing channel/program info
export const $playing: Store<{
  programId: string
  programTitle: string
  duration: string
} | null> = combine(
  {
    channel: channelsEntity.$channel,
    epg: epgProgramsModel.$epg,
    now: clock.$minutes,
  },
  ({ channel, epg: [programs], now }) => {
    if (channel == null) return null
    const epg = programs.get(channel.channelEpgId ?? NO_EPG_ID)
    if (epg == null) return null

    const isLive = inRange(now)
    const program = epg.find((p) => isLive([p.actualStartMs, p.actualStopMs]))
    if (program == null) return null

    // set duration '0' if no EPG (=== isEmpty)
    const duration =
      program.isEmpty || !program.durationMs
        ? 0
        : program.durationMs / ONE_SECOND_MS

    // set programId '' if no EPG (=== isEmpty)
    const programId = program.isEmpty ? '' : program.id || ''

    return {
      programId,
      programTitle: program.name || NO_EDG_DATA,
      duration: String(duration),
    }
  }
)

// previous state to send correct analytics in the event `play_stop` (as current channel is reseted after switching channels)
export const $prevPlaying = previous($playing)
