import { type LiveChannelFormatted } from '$/channels'
import {
  CardsInRow,
  FIXED_INDENTS,
  IOverlayLayoutProps,
  LAYOUT,
  LiveChannelCard,
  UVOTestGridTemplate,
  type ITestGridProps,
} from '@setplex/arcane'
import { LiveChannel, MediaTypes } from '@setplex/tria-api'
import { useUnit } from 'effector-react'
import { type SyntheticEvent } from 'react'
import { model as favoritesModel } from '~/features/favorites'

interface IRowProps {
  className?: string
  content?: LiveChannelFormatted[] | LiveChannel[] | null
  title?: ITestGridProps['title']
  overlayEnabled?: IOverlayLayoutProps['overlayEnabled']
  overlayWidth?: IOverlayLayoutProps['overlayWidth']
  overlayHeight?: IOverlayLayoutProps['overlayHeight']
  overlayHorizontalColor?: IOverlayLayoutProps['overlayHorizontalColor']
  overlayVerticalColor?: IOverlayLayoutProps['overlayVerticalColor']
  backgroundPosterEnabled?: ITestGridProps['backgroundPosterEnabled']
  backgroundPosterUrl?: ITestGridProps['backgroundPosterUrl']
  marginTitle?: ITestGridProps['marginTitle']
  updatedTitle?: boolean // New Text component will be rendered in Grid if true
  onLoadMoreItems?: () => void
  paddings?: (FIXED_INDENTS | LAYOUT)[]
  margins?: FIXED_INDENTS[]
  onClickChannelCard?: () => void
}

ChannelGrid.displayName = 'ChannelGrid'
export function ChannelGrid({
  content,
  className = '',
  title = '',
  marginTitle,
  onLoadMoreItems,
  onClickChannelCard,
  ...rest
}: IRowProps): JSX.Element {
  const favorite = useUnit(favoritesModel.$favorites)
  const handleFavoriteBtnClick = useUnit(favoritesModel.handleFavoriteBtnClick)

  return (
    <UVOTestGridTemplate
      title={title}
      updatedTitle
      onLoadMoreItems={onLoadMoreItems}
      className={className}
      cardsInRow={CardsInRow.Horizontal_4}
      tvChannelCard
      marginTitle={marginTitle}
      {...rest}
    >
      {content?.length
        ? content.map((card, index) => {
            const { id, logo, link, title: channelTitle } = card

            const isFavorite = Boolean(favorite.live_channel[id])
            const onClick = (e: SyntheticEvent) => {
              e.preventDefault()
              e.stopPropagation()

              handleFavoriteBtnClick?.({
                id,
                isFavorite: !isFavorite,
                contentType: MediaTypes.LIVE_CHANNEL,
                creatorId: '',
                contentTitle: channelTitle,
              })
            }

            return (
              <a key={`${index}${id}`} href={link}>
                <LiveChannelCard
                  posterUrl={logo}
                  isFavorite={isFavorite}
                  handleFavoriteClick={onClick}
                  onClick={onClickChannelCard}
                />
              </a>
            )
          })
        : null}
    </UVOTestGridTemplate>
  )
}
